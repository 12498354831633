import React, { useState } from "react"
import Image from "gatsby-plugin-sanity-image"

const Enquiry = (props) => {
  const { data } = props
  const site = data ? data.site : null
  const [loaded, setLoaded] = useState(false)

  if (!site) {
    return <div>Loading...</div>
  }

  return (
    <>
      {site.enquiryCTATitle && site.enquiryCTAImage && site.enquiryCTAText && (
        <div className="enquiry-cta">
          <div className="container flex flex-col gap-6 py-7 md:grid md:grid-cols-12 md:py-0 md:mt-10 md:gap-10">
            <div className="md:col-span-4 md:pb-10 md:pr-10 md:border-r md:border-grey-default">
              {site.enquiryCTAImage && (
                <Image
                  {...site.enquiryCTAImage}
                  width={605}
                  height={880}
                  alt={site.enquiryCTAText}
                  className={
                    `max-w-[75%] md:max-w-[100%] max-h-[880px] w-auto lazy ` +
                    (loaded && " loaded")
                  }
                  onLoad={() => setLoaded(true)}
                />
              )}
            </div>
            <div className="flex flex-col md:col-span-8 md:pb-10">
              {site.enquiryCTATitle ? (
                <h2>{site.enquiryCTATitle}</h2>
              ) : (
                <h2>Make an enquiry</h2>
              )}
              {site.enquiryCTAText ? (
                <h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-auto md:mb-10 max-w-[680px]">
                  {site.enquiryCTAText}
                </h3>
              ) : (
                <h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-auto md:mb-10 max-w-[680px]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </h3>
              )}
              <button type="button" className="link text-[#1B1B1B] text-[14px]">
                Enquire now
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Enquiry
